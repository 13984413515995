<template>
  <div>
    <!-- <v-btn color="success" small dark @click="nonEmployed">
      Hodim +
    </v-btn> -->
    <div class="card-toolbar">
      <a @click="nonEmployed" class="btn btn-primary font-weight-bolder">
        <span class="svg-icon svg-icon-white"
          ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/General/User.svg--><svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <polygon points="0 0 24 0 24 24 0 24" />
              <path
                d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                fill="#000000"
                fill-rule="nonzero"
                opacity="0.3"
              />
              <path
                d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                fill="#000000"
                fill-rule="nonzero"
              />
            </g></svg
          ><!--end::Svg Icon--></span
        >

        {{ $t('MENU.STAFF.STAFF') }} +</a
      >
    </div>

    <v-dialog v-model="dialog" max-width="450">
      <v-card>
        <v-card-title class="headline">Hodim qoshish</v-card-title>
        <v-card-text>
          <v-text-field
            label="Username"
            v-model="username"
            dense
            outlined
          ></v-text-field>

          <v-text-field
            label="Password"
            type="password"
            v-model="password"
            dense
            outlined
          ></v-text-field>

          <v-autocomplete
            v-model="nonEmployedSelect"
            :items="getNonEmployedList"
            label="Personal Ma'lumot"
            item-text="full_name"
            item-value="id"
            return-object
            outlined
            dense
          ></v-autocomplete>

          <v-dialog
            ref="dialog3"
            v-model="modal"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="date | dataFilter"
                label="Ishga olingan sana"
                append-icon="event"
                readonly
                v-bind="attrs"
                outlined
                dense
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker no-title v-model="date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">Yopish</v-btn>
              <v-btn text color="primary" @click="$refs.dialog3.save(date)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>

          <v-autocomplete
            v-model="ss"
            :items="getDepartments"
            label="Hodim Department"
            item-text="name"
            item-value="id"
            @change="getPos"
            outlined
            dense
          ></v-autocomplete>

          <!---{{getPositionStaff}} -->

          <div v-if="showPos">
            <v-autocomplete
              v-model="staffPosition"
              :items="pos"
              label="Hodim lavozimi"
              item-text="name"
              item-value="id"
              outlined
              dense
            ></v-autocomplete>
          </div>
          <v-text-field
            label="Izoh"
            v-model="comments"
            dense
            outlined
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error"
            :disabled="newStaffLoading"
            @click="dialog = false"
          >
            Bekor qilish
          </v-btn>

          <v-btn color="success" :disabled="newStaffLoading" @click="submit"
            ><i v-if="newStaffLoading" class="el-icon-loading"></i> Yuborish
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newStaffLoading: false,
      nonEmployedSelect: '',
      oblastSelect: '',
      dialog: false,
      staffPosition: '',
      username: '',
      password: '',
      ss: '',
      showPos: false,
      ff: '',
      comments: '',
      staffpersonal: '',
      position: '',
      date: '',
      pos: {},
      modal: false
    }
  },
  created() {
    this.$store.dispatch('staffDepartments')
    this.$store.dispatch('staffPositions')
    this.$store.dispatch('getAllNonEmployedStaff')
    this.$store.dispatch('getAllGroups')
  },
  watch: {
    username: function (val) {
      this.username = val.toLowerCase()
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(this.username))
        this.username = this.username.replace(/\s/g, '')
    },
    ss(val) {
      this.pos = this.getPositionStaff.find((obj) => obj.id === val)
    }
  },
  filters: {
    dataFilter(val) {
      if (val) {
        return val.split('-').reverse().join('-')
      } else return val
    }
  },
  computed: {
    getPositionStaff() {
      return this.$store.getters.staffPositions
    },
    getDepartments() {
      return this.$store.getters.staffDepartments
    },
    getNonEmployedList() {
      // return this.$store.getters.allNonEmployedStaff;
      return this.$store.state.requests.allNonEmployedStaff
    },
    allGroups() {
      console.log(
        'this.$store.state.requests.allGroups',
        this.$store.state.requests.allGroups
      )
      return this.$store.state.requests.allGroups.find(
        (obj) => obj.name == 'staff'
      )
    },
    getDriverGroup() {
      return this.$store.state.requests.allGroups.find(
        (obj) => obj.name == 'driver'
      )
    }
  },
  methods: {
    nonEmployed() {
      this.$store.dispatch('getAllPersonal')
      this.dialog = true
    },
    getPos() {
      this.showPos = true
    },
    oblastChange(value) {
      if (value.id !== null) {
        console.log(value)
      }
    },
    submit() {
      console.log(this.allGroups)
      const data = {
        staffpersonal: this.nonEmployedSelect.id,
        position: this.staffPosition,
        comments: this.comments,
        employed_from: this.date,
        user_data: {
          username: this.username,
          password: this.password,
          groups: this.allGroups.id
        }
      }
      if (this.ss == 3) {
        data.user_data.groups = this.getDriverGroup.id
      }
      console.log(data)
      this.newStaffLoading = true
      this.$store
        .dispatch('createAllStaff', data)
        .then(() => {
          this.newStaffLoading = false
          this.username = ''
          this.password = ''
          this.ss = ''
          this.date = ''
          this.employed_from = ''
          this.staffPosition = ''
          this.nonEmployedSelect = ''
          this.comments = ''
          this.dialog = false
        })
        .catch((err) => {
          this.newStaffLoading = false
          console.error(err)
        })
      // this.$v.$reset()
    }
  }
}
</script>

<style></style>
